<template>
  <base-checkbox :id="id" v-model="checked" :item="checkboxPrivacy"></base-checkbox>
</template>

<script setup lang="ts">
import {computed, onMounted, ref} from '#imports';
import BaseCheckbox from '@/components/Base/checkbox.vue';

const props = defineProps({
  modelValue: {type: Boolean, required: true},
  id: {type: String, required: false, default: 'privacy'},
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const privacyText = ref('');
const setPrivacyText = (isActive) => {
  privacyText.value = `<div class="privacy${
    isActive ? ' privacy-selected' : ''
  }">Вы соглашаетесь с&nbsp<a class="link" target="_blank" href="https://www.t-mash.ru/terms">условиями оферты</a>&nbspи&nbsp<a class="link" target="_blank" href="https://www.t-mash.ru/policy/">политикой конфиденциальности</a></div>`;
};

const checked = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    setPrivacyText(val);
    emit('update:modelValue', val);
  },
});

onMounted(() => {
  setPrivacyText(checked.value);
});

const checkboxPrivacy = ref({
  value: checked.value,
  name: computed(() => privacyText.value),
});
</script>
